import createAuth0Client, {
  Auth0Client,
  CacheLocation,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-spa-js';
import { App, Plugin, computed, reactive, InjectionKey, ComputedRef } from 'vue';
import { ZERO } from '@/constants';
import { USER_ROLES } from '@/constants/roles';

let client: Auth0Client;

/**
 * Any additional info we want to pass to the Auth0 to be returned after successful authorization.
 */
export interface CallbackAppState {
  targetUrl: string;
}

interface Auth0PluginState {
  loading: boolean;
  isAuthenticated: boolean;
  user: User | undefined;
  popupOpen: boolean;
  error: unknown;
}

interface Auth0PluginOptions {
  domain: string;
  clientId: string;
  audience: string;
  redirectUri: string;
  useRefreshTokens: boolean;
  cacheLocation: string;

  onRedirectCallback(appState: CallbackAppState): void;
}

export interface Auth0Plugin {
  init(options: Auth0PluginOptions): Promise<Plugin>;
}

export interface AuthService {
  isAuthenticated: ComputedRef<boolean>;
  loading: ComputedRef<boolean>;
  user: ComputedRef<User | undefined>;
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
  getIdTokenClaims(o?: GetIdTokenClaimsOptions): Promise<IdToken | undefined>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string>;
  getUserRole(): string;
  handleRedirectCallback(): Promise<void>;
  logout(o?: LogoutOptions): Promise<void> | void;
  isUserBroker(): boolean;
  isUserAdmin(): boolean;
  isUserUnderwriter(): boolean;
  isUserPartner(): boolean;
  isUserPricingMaker(): boolean;
  isUserScrollAdmin(): boolean;
}

/**
 * Creating instance of InjectionKey in order to properly type our service for Composition API and Provide/Inject methods.
 * @type {typeof AuthServiceKey}
 */
export const AuthServiceKey: InjectionKey<AuthService> = Symbol('Auth');

const state = reactive<Auth0PluginState>({
  loading: true,
  isAuthenticated: false,
  user: {},
  popupOpen: false,
  error: null,
});

async function handleRedirectCallback(): Promise<void> {
  state.loading = true;

  try {
    await client.handleRedirectCallback();
    state.user = await client.getUser();
    state.isAuthenticated = true;
  } catch (e) {
    state.error = e;
  } finally {
    state.loading = false;
  }
}

function loginWithRedirect(o: RedirectLoginOptions): Promise<void> {
  return client.loginWithRedirect(o);
}

function getIdTokenClaims(o: GetIdTokenClaimsOptions): Promise<IdToken | undefined> {
  return client.getIdTokenClaims(o);
}

function getTokenSilently(o: GetTokenSilentlyOptions): Promise<string> {
  return client.getTokenSilently(o);
}

function getTokenWithPopup(o: GetTokenWithPopupOptions): Promise<string> {
  return client.getTokenWithPopup(o);
}

function logout(o?: LogoutOptions): Promise<void> | void {
  return client.logout(o);
}

function getUserRole(): string {
  return state.user?.[`${process.env.VUE_APP_AUTH0_AUDIENCE}/roles`][ZERO] ?? '';
}

function hasUserRole(userRole: USER_ROLES): boolean {
  return state.user?.[`${process.env.VUE_APP_AUTH0_AUDIENCE}/roles`].some((role: USER_ROLES) => role === userRole);
}

function isUserBroker(): boolean {
  return hasUserRole(USER_ROLES.BROKER);
}

function isUserUnderwriter(): boolean {
  return hasUserRole(USER_ROLES.UNDERWRITER);
}

function isUserAdmin(): boolean {
  return hasUserRole(USER_ROLES.ADMIN);
}

function isUserPartner(): boolean {
  return hasUserRole(USER_ROLES.PARTNER);
}

function isUserPricingMaker(): boolean {
  return hasUserRole(USER_ROLES.PRICING_MAKER);
}

function isUserScrollAdmin(): boolean {
  return hasUserRole(USER_ROLES.SCROLL_ADMIN);
}

/**
 * Main Plugin instance object that is being exported
 * @type AuthService
 */
export const authPlugin: AuthService = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  getUserRole,
  handleRedirectCallback,
  loginWithRedirect,
  logout,
  isUserBroker,
  isUserAdmin,
  isUserUnderwriter,
  isUserPartner,
  isUserPricingMaker,
  isUserScrollAdmin,
};

async function init(options: Auth0PluginOptions): Promise<Plugin> {
  client = await createAuth0Client({
    // domain: process.env.VUE_APP_AUTH0_DOMAIN,
    // client_id: process.env.VUE_APP_AUTH0_CLIENT_KEY,
    domain: options.domain,
    client_id: options.clientId,
    audience: options.audience,
    redirect_uri: options.redirectUri,
    useRefreshTokens: options.useRefreshTokens,
    cacheLocation: <CacheLocation>options.cacheLocation,
  });

  try {
    // If the user is returning to the app after authentication
    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback();

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      options.onRedirectCallback(appState);
    }
  } catch (e) {
    state.error = e;
  } finally {
    // Initialize our internal authentication state
    state.isAuthenticated = await client.isAuthenticated();
    state.user = await client.getUser();
    state.loading = false;
  }

  return {
    install: (app: App) => {
      app.provide(AuthServiceKey, authPlugin);
    },
  };
}

export const Auth: Auth0Plugin = {
  init,
};
