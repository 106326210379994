import { IBorrower } from '@/types/IBorrower';
import { IAddress } from '@/types/IAddress';
import { ISpeakWith } from '@/types/ISpeakWith';
import { ZERO } from '@/constants';
import Address from '@/models/Address';

interface BorrowerProps {
  id?: number;
  index?: number;
  title?: string;
  name?: string;
  fullName?: string;
  middleName?: string;
  surname?: string;
  previousLastName?: string;
  birthDate?: string;
  birthDateReliable?: boolean | undefined;
  email?: string;
  phone?: string;
  loanCase?: string | undefined;
  activeDirector?: boolean | undefined;
  shareholder?: boolean | undefined;
  speakWith?: ISpeakWith | undefined;
  currentAddress?: IAddress | undefined;
  previousAddress?: IAddress | undefined;
}

class Borrower implements IBorrower {
  id: number;
  index: number;
  title: string;
  name: string;
  fullName: string;
  middleName: string | undefined;
  surname: string;
  previousLastName: string;
  birthDate: string;
  birthDateReliable: boolean | undefined;
  email: string;
  phone: string;
  loanCase: string | undefined;
  activeDirector: boolean | undefined;
  shareholder: boolean | undefined;
  speakWith: ISpeakWith | undefined;
  currentAddress: IAddress | undefined;
  previousAddress: IAddress | undefined;

  constructor({
    id,
    index,
    title,
    name,
    fullName,
    middleName,
    surname,
    previousLastName,
    birthDate,
    birthDateReliable,
    email,
    phone,
    loanCase,
    activeDirector,
    shareholder,
    speakWith,
    currentAddress,
    previousAddress,
  }: BorrowerProps) {
    this.id = id || ZERO;
    this.index = index || ZERO;
    this.title = title || '';
    this.name = name || '';
    this.fullName = fullName || '';
    this.middleName = middleName || '';
    this.surname = surname || '';
    this.previousLastName = previousLastName || '';
    this.birthDate = birthDate || '';
    this.birthDateReliable = birthDateReliable || true;
    this.email = email || '';
    this.phone = phone || '';
    this.activeDirector = activeDirector || false;
    this.shareholder = shareholder || false;
    this.loanCase = loanCase || undefined;
    this.currentAddress = currentAddress || undefined;
    this.previousAddress = previousAddress || undefined;
    this.speakWith = speakWith || undefined;
  }

  store(): void {
    localStorage.setItem('borrowers', JSON.stringify(this));
  }

  static getStored(): Borrower {
    return new Borrower(JSON.parse(localStorage.getItem('borrowers') || ''));
  }

  static init(index: number): IBorrower {
    return new Borrower({
      id: ZERO,
      index,
      title: '',
      name: '',
      fullName: '',
      middleName: '',
      surname: '',
      previousLastName: '',
      birthDate: '',
      birthDateReliable: true,
      email: '',
      phone: '',
      loanCase: '',
      activeDirector: false,
      shareholder: false,
      currentAddress: Address.init(),
      previousAddress: Address.init(),
    });
  }
}

export default Borrower;
