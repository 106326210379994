import { IBorrower } from '@/types/IBorrower';
import { authPlugin } from '@/services/auth';
import HttpClient from '@/http/index';
import { AxiosPromise } from 'axios';

export async function postBorrower(borrower: IBorrower, loanCaseId: string): Promise<AxiosPromise<IBorrower>> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'POST',
    url: `/application/borrower/v1/borrower`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      loanCaseId,
    },
    data: borrower,
  });
}

export async function putSpeakWithBorrower(borrowerId: number, status: string): Promise<AxiosPromise<IBorrower>> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'PUT',
    url: `/application/borrower/v1/borrower/${borrowerId}/speakWith`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      status,
    },
  });
}

export async function deleteBorrower(borrowerId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'DELETE',
    url: `/application/borrower/v1/borrower/${borrowerId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
