import routeNames from '@/router/routeNames';
import { USER_ROLES } from '@/constants/roles';
import { RouteLocationNormalized } from 'vue-router';

const PARTNER_ACCESS = [routeNames.QUICK_QUOTES, routeNames.LOGOUT];
const PRICING_MAKER_ACCESS = [routeNames.PRICING_CONFIGURATION, routeNames.LOGOUT];
const SCROLL_ADMIN_ACCESS = [
  routeNames.APPROVAL_QUEUE,
  routeNames.APPROVE_PRICING_CONFIGURATION,
  routeNames.PRICING_CONFIGURATION,
  routeNames.LOGOUT,
];

export const hasAccessOnRoute = (to: RouteLocationNormalized, role: string): boolean => {
  switch (role) {
    case USER_ROLES.BROKER:
    case USER_ROLES.UNDERWRITER:
      return true; // Currently, both roles have access everywhere
    case USER_ROLES.PARTNER:
      return PARTNER_ACCESS.some((routeName) => routeName === to.name);
    case USER_ROLES.PRICING_MAKER:
      return PRICING_MAKER_ACCESS.some((routeName) => routeName === to.name);
    case USER_ROLES.SCROLL_ADMIN:
      return SCROLL_ADMIN_ACCESS.some((routeName) => routeName === to.name);
    default:
      throw new Error('Unknown role');
  }
};

export const redirectTo = (role: string): string => {
  switch (role) {
    case USER_ROLES.BROKER:
    case USER_ROLES.UNDERWRITER:
      return routeNames.DASHBOARD;
    case USER_ROLES.PARTNER:
      return routeNames.QUICK_QUOTES;
    case USER_ROLES.PRICING_MAKER:
      return routeNames.PRICING_CONFIGURATION;
    case USER_ROLES.SCROLL_ADMIN:
      return routeNames.APPROVAL_QUEUE;
    default:
      throw new Error('Unknown role');
  }
};
