import { IUser } from '@/types/IUser';

class User implements IUser {
  email: string;
  name: string;
  token: string;
  role: string;

  constructor(email?: string, name?: string, token?: string, role?: string | null) {
    this.email = email || 'N/A';
    this.name = name || 'N/A';
    this.token = token || '';
    this.role = role || 'N/A';
  }
}

export default User;
