import { ICase } from '@/types/ICase';
import { Module } from 'vuex';
import { RootState } from '@/store';
import { ResponseCheckModel, VALIDATION_TYPES, validationsForCompany } from '@/constants/validations';
import { checkOpenBanking, manuallyValidateOpenBanking } from '@/http/validations/ExternalServices';
import { getValidationCheck } from '@/http/validations/Check';
import { removeDocument, validationDocumentUpload } from '@/http/validations/Document';
import { ZERO } from '@/constants';
import { CASE_TYPE } from '@/types/CaseTypes';

export interface ValidationState {
  caseLoan: ICase | null;
  validations: Array<ResponseCheckModel>;
  borrowerValidations: Map<number, Map<VALIDATION_TYPES, ResponseCheckModel>>;
  areAllValidationsValid: boolean;
}

export enum ValidationGetters {
  VALIDATIONS = 'validation/validations',
  VALIDATIONS_MAIN_BORROWER = 'validation/validationsMainBorrower',
  VALIDATIONS_COMPANY = 'validation/validationsCompany',
  VALIDATIONS_BORROWER = 'validation/validationsBorrower',
  ARE_ALL_VALIDATIONS_VALID = 'validation/areAllValidationsValid',
}

export enum ValidationActions {
  FETCH_VALIDATIONS = 'validation/fetchValidations',
  UPLOAD_DOCUMENT = 'validation/uploadDocument',
  DELETE_DOCUMENT = 'validation/deleteDocument',
  INITIATE_OPEN_BANKING = 'validation/initiateOpenBanking',
  CHECK_OPEN_BANKING = 'validation/checkOpenBanking',
}

const validationStore: Module<ValidationState, RootState> = {
  namespaced: true,

  state: {
    caseLoan: null,
    validations: [],
    borrowerValidations: new Map(),
    areAllValidationsValid: false,
  },

  mutations: {
    setCaseLoan(state, caseLoan) {
      state.caseLoan = caseLoan;
    },
    setValidations(state, validations) {
      state.validations = [...validations];
    },
    setAllValidationsValid(state, valid) {
      state.areAllValidationsValid = valid;
    },
    updateValidation(state, validation: ResponseCheckModel) {
      const index = state.validations.findIndex((v) => v.id === validation.id);
      state.validations[index] = validation;
    },
  },

  actions: {
    async fetchValidations({ commit, rootGetters }) {
      const caseLoan: ICase = rootGetters['case/getActiveCase'];
      const response = (await getValidationCheck(caseLoan.number)).data;

      commit('setCaseLoan', caseLoan);
      commit('setValidations', response.validations);
      commit('setAllValidationsValid', response.proceed);
    },
    async uploadDocument({ commit }, { validationId, document }) {
      const response = await validationDocumentUpload(document, validationId);
      commit('updateValidation', response.data);
    },
    async deleteDocument({ dispatch }, { validationId, documentId }) {
      await removeDocument(validationId, documentId);
      // TODO BACKEND not returning the validation
      dispatch('fetchValidations');
    },
    async initiateOpenBanking({ dispatch }, caseNumber) {
      await manuallyValidateOpenBanking(caseNumber);
      dispatch('fetchValidations');
    },
    async checkOpenBanking({ dispatch }, caseNumber) {
      await checkOpenBanking(caseNumber);
      dispatch('fetchValidations');
    },
  },

  getters: {
    validations: (state) => state.validations,
    validationsMainBorrower: (state) =>
      state.validations.filter(
        (v: ResponseCheckModel) =>
          (v.borrowerId === null && state.caseLoan?.type === CASE_TYPE.INDIVIDUAL) ||
          v.borrowerId === state.caseLoan?.borrowers[ZERO].id,
      ),
    validationsCompany: (state) =>
      state.validations.filter((v: ResponseCheckModel) => validationsForCompany.includes(v.type)),
    validationsBorrower: (state) => (borrowerId: number) =>
      state.validations.filter((v: ResponseCheckModel) => v.borrowerId === borrowerId),
    areAllValidationsValid: (state) => state.areAllValidationsValid,
  },
};

export default validationStore;
