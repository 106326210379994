import axios from 'axios';
import { authPlugin } from '@/services/auth';

export default async function () {
  const token = await authPlugin.getTokenSilently();
  return axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}
