export enum SORT {
  ASC = 'asc',
  DESC = 'desc',
  NONE = '',
}

export interface ISortBy {
  fieldName: string;
  direction: SORT;
}
