import { CASE_STATUS } from '@/types/CaseStatus';

export const STATUS_TO_TEXT: Record<CASE_STATUS, string> = {
  [CASE_STATUS.PRE_DRAFT]: 'Pre-Application',
  [CASE_STATUS.DRAFT]: 'Application',
  [CASE_STATUS.ACTIVE_SUMMARY_REQUEST]: 'Case Summary',
  [CASE_STATUS.ACTIVE_INITIAL_QUOTES]: 'Indicative Quotes',
  [CASE_STATUS.ACTIVE_CONDITIONAL_OFFER]: 'Conditional Offer',
  [CASE_STATUS.ACTIVE_VALIDATIONS]: 'Validations',
  [CASE_STATUS.ACTIVE_FINAL_CALL]: 'Speak-With',
  [CASE_STATUS.ACTIVE_SETUP_LEGALS]: 'Legals (setup)',
  [CASE_STATUS.ACTIVE_LEGALS]: 'Legals',
  [CASE_STATUS.ACTIVE_DISBURSED]: 'Review & Disbursal',
  [CASE_STATUS.CLOSED_EXPIRED]: 'Expired',
  [CASE_STATUS.CLOSED_REJECTED]: 'Rejected',
  [CASE_STATUS.CLOSED_APPROVED]: 'Disbursed',
};
