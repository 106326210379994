import httpClient from '@/http';
import { AxiosPromise } from 'axios';
import { authPlugin } from '@/services/auth';

export async function downloadDocument(checkId: number, documentId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return httpClient({
    method: 'GET',
    url: `/application/validation/v1/validation/${checkId}/${documentId}/download-document`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}

export async function removeDocument(checkId: number, documentId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return httpClient({
    method: 'PUT',
    url: `/application/validation/v1/validation/${checkId}/${documentId}/remove-document`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
/**
 *
 * @param {File} file
 * @param {number} validationId
 * @returns {Promise<AxiosPromise>}
 */
export async function validationDocumentUpload(file: File, validationId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  const data = new FormData();
  data.append('file', file);

  return httpClient({
    method: 'PUT',
    url: `/application/validation/v1/validation/${validationId}/document-upload`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}
