import Vuex from 'vuex';

// Vuex Modules
import userStore from '@/store/modules/user';
import borrowerStore from '@/store/modules/borrower';
import caseApplicationStore from '@/store/modules/caseApplication';
import caseStore from '@/store/modules/case';
import validationStore from '@/store/modules/validation';
import legalsStore from '@/store/modules/legals';
import notificationsStore from '@/store/modules/notifications';
import pricingConfigurationStore from '@/store/modules/pricingConfiguration';

// This is just to comply with TS currently top level root state does not have any key so just creating a placeholder
// type for it until we start using it. Once we start adding keys to the state just remove this eslint ignore comment.
// eslint-disable-next-line @typescript-eslint/ban-types
export type RootState = {};

export default new Vuex.Store<RootState>({
  modules: {
    user: userStore,
    borrower: borrowerStore,
    caseApplication: caseApplicationStore,
    case: caseStore,
    validation: validationStore,
    legal: legalsStore,
    notifications: notificationsStore,
    pricingConfiguration: pricingConfigurationStore,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});
