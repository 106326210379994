import { RouteLocationNormalized } from 'vue-router';
import routeNames from '@/router/routeNames';
import { ICaseAccess } from '@/router/guards/case/caseGuard';
import { ICase } from '@/types/ICase';
import { CASE_STATUS } from '@/types/CaseStatus';
import { IUserCaseAccess } from '@/router/guards/case/IUserCaseAccess';

export const access: Record<string, IUserCaseAccess> = {
  [CASE_STATUS.PRE_DRAFT]: {
    routes: [routeNames.PRE_APPLICATION],
  },
  [CASE_STATUS.DRAFT]: {
    routes: [routeNames.APPLICATION],
  },
  [CASE_STATUS.ACTIVE_SUMMARY_REQUEST]: {
    routes: [routeNames.CASE_SUMMARY, routeNames.BORROWER_NOT_FOUND],
  },
  [CASE_STATUS.ACTIVE_INITIAL_QUOTES]: {
    routes: [routeNames.CASE_SUMMARY, routeNames.INDICATIVE_QUOTES],
  },
  [CASE_STATUS.ACTIVE_CONDITIONAL_OFFER]: {
    routes: [routeNames.CASE_SUMMARY, routeNames.CONDITIONAL_OFFER],
  },
  [CASE_STATUS.ACTIVE_VALIDATIONS]: {
    routes: [routeNames.CASE_SUMMARY, routeNames.VALIDATIONS],
  },
  [CASE_STATUS.ACTIVE_FINAL_CALL]: {
    routes: [routeNames.CASE_SUMMARY, routeNames.SPEAK_WITH],
  },
  [CASE_STATUS.ACTIVE_SETUP_LEGALS]: {
    routes: [routeNames.CASE_SUMMARY, routeNames.LEGALS_UPLOAD],
  },
  [CASE_STATUS.ACTIVE_LEGALS]: {
    routes: [routeNames.CASE_SUMMARY, routeNames.LEGALS],
  },
  [CASE_STATUS.ACTIVE_DISBURSED]: {
    routes: [routeNames.CASE_SUMMARY, routeNames.DISBURSAL],
  },
  [CASE_STATUS.CLOSED_EXPIRED]: {
    routes: [routeNames.CASE_SUMMARY],
  },
  [CASE_STATUS.CLOSED_REJECTED]: {
    routes: [routeNames.CASE_SUMMARY],
  },
  [CASE_STATUS.CLOSED_APPROVED]: {
    routes: [routeNames.CASE_SUMMARY, routeNames.DISBURSAL],
  },
};

export class BrokerCaseAccess implements ICaseAccess {
  hasAccess(activeCase: ICase, to: RouteLocationNormalized): boolean {
    const nameRoute = to.name ?? '';
    return access[activeCase.status].routes.includes(nameRoute.toString());
  }

  redirectTo(): string {
    return routeNames.DASHBOARD;
  }
}
