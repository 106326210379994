<template>
  <TransitionGroup
    v-if="group"
    :tag="tag"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
    move-class="fade-move"
    v-bind="$attrs"
    @before-enter="setDuration"
    v-on="hooks"
  >
    <slot></slot>
  </TransitionGroup>
  <Transition
    v-else
    :tag="tag"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
    move-class="fade-move"
    v-bind="$attrs"
    @before-enter="setDuration"
    v-on="hooks"
  >
    <slot></slot>
  </Transition>
</template>
<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 300,
    },
    group: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    hooks() {
      return {
        beforeEnter: this.setDuration,
        afterEnter: this.cleanUpDuration,
        beforeLeave: this.setDuration,
        afterLeave: this.cleanUpDuration,
        leave: this.setStylesForLeave,
      };
    },
  },
  methods: {
    setDuration(el) {
      el.style.animationDuration = `${this.duration}ms`;
    },
    cleanUpDuration(el) {
      el.style.animationDuration = '';
    },
    setStylesForLeave(el) {
      if (this.group) {
        el.style.position = 'relative';
        el.style.right = `${-el.clientWidth}px`;
      }
    },
  },
};
</script>
<style>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}
.fade-move {
  transition: transform 0.3s ease-out;
}
</style>
