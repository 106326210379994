import HttpClient from '@/http/index';
import { authPlugin } from '@/services/auth';
import { AxiosPromise } from 'axios';
import { IProperty } from '@/types/IProperty';
import { ILoan } from '@/types/ILoan';
import Borrower from '@/models/Borrower';
import { ICompany } from '@/types/ICompany';
import { CASES_PER_PAGE } from '@/constants';
import { ICase } from '@/types/ICase';
import getHttpClientWithAuth from '@/http/getHttpClientWithAuth';

export interface UpdateAllRequestModel {
  borrowers: Array<Borrower>;
  properties: Array<IProperty>;
  loan: ILoan;
  company?: ICompany | undefined;
  portfolioLandlord: boolean;
}

export async function rejectCase(caseId: number, comment: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'PUT',
    url: `/application/case/v1/case/${caseId}/rejection?comment=${comment}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/**
 * API call to fetch case by ID
 * @param {number} caseId
 * @returns {Promise<AxiosPromise>}
 */
export async function getCaseById(caseId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  const caseObject = await HttpClient({
    method: 'GET',
    url: `/application/case/v1/case/${caseId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return caseObject;
}

/**
 *
 * @param {boolean} activeCases
 * @param {number} offSet
 * @param {string} sortBy
 * @param {string} filter
 * @returns {Promise<AxiosPromise>}
 */
export async function getAllCases(
  activeCases: boolean,
  offSet: number,
  sortBy: string,
  filter: string,
): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'GET',
    url: `application/case/v1/case?active=${activeCases}&size=${CASES_PER_PAGE}&page=${offSet}&${sortBy}&filter=${filter}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function proceedToFinalCall(caseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'PUT',
    url: `/application/case/v1/case/${caseId}/proceedToFinalCall`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function proceedToLegals(caseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'PUT',
    url: `/application/case/v1/case/${caseId}/proceedToLegals`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function proceedToFinalLegals(caseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'PUT',
    url: `/application/case/v1/case/${caseId}/proceedToFinalLegals`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function closeCase(caseId: string) {
  const client = await getHttpClientWithAuth();
  return client.put(`/application/case/v1/case/${caseId}/closeCase`);
}

export async function approval(caseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'PUT',
    url: `/application/case/v1/case/${caseId}/approval`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// to be called the first time
export async function updateAllAtOnce(caseId: string, data: UpdateAllRequestModel): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return HttpClient({
    method: 'PUT',
    url: `/application/case/v1/case/${caseId}/allAtOnce`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
}

// to be called all the subsequent times
export async function editAllAtOnce(caseId: string, data: UpdateAllRequestModel): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return HttpClient({
    method: 'PUT',
    url: `/application/case/v1/case/${caseId}/edit`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
}

export async function createCompanyLoanCase(companyNumber: string, quickQuoteId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'POST',
    url: `/application/case/v1/case/createCompanyLoanCase`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      companyNumber,
      quickQuoteId,
    },
  });
}

export async function assignMainBorrower(caseId: string, borrowerId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'PUT',
    url: `/application/case/v1/case/${caseId}/assignMainBorrower/${borrowerId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function proceedToDraft(caseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'PUT',
    url: `/application/case/v1/case/${caseId}/proceedToDraft`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/**
 * Returns a list of fields that should be editable in Borrower Editor
 * @param {string} caseId
 * @returns {Promise<AxiosPromise>}
 */
export async function getEditableFields(caseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return HttpClient({
    method: 'GET',
    url: `/application/case/v1/case/${caseId}/getEditableFields`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/**
 *
 * @param {number} requestedNumberOfBorrowers
 * @returns {Promise<AxiosPromise>}
 */
export async function initiateCase(
  requestedNumberOfBorrowers: number,
  quickQuoteId?: number,
): Promise<AxiosPromise<ICase>> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    url: `application/case/v1/case`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      requestedNumberOfBorrowers,
      quickQuoteId,
    },
  });
}

export async function repriceCase(caseId: string): Promise<AxiosPromise<ICase>> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    url: `/application/case/v1/case/${caseId}/reprice`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function confirmNewRepricedCase(caseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    url: `/application/case/v1/case/${caseId}/dismissModalWindow`,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: null,
  });
}

export async function confirmNewRecalculatedCase(caseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    url: `/application/case/v1/case/${caseId}/dismissRecalculationModalWindow`,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: null,
  });
}

export async function dismissGoodNewsModal(caseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    url: `/application/case/v1/case/${caseId}/dismissMultipleSetsOfQuotesModal`,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: null,
  });
}

/**
 *
 * @param {number} caseId
 * @returns {AxiosPromise<any>}
 */
export async function deleteCase(caseId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    url: `/application/case/v1/case/${caseId}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

interface IFeedbackDto {
  starRating: number;
  comment?: string | null;
}
export async function postFeedback(loanCaseId: string, postData: IFeedbackDto) {
  const client = await getHttpClientWithAuth();

  return client.post(`/application/case/v1/case/${loanCaseId}/feedback`, postData);
}
