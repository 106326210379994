import { Module } from 'vuex';
import { ICase } from '@/types/ICase';
import { getAllCases, getCaseById } from '@/http/Case';
import { RootState } from '@/store/index';
import { IProperty } from '@/types/IProperty';
import { ONE, ZERO } from '@/constants';

export interface ICasesSetting {
  sort: string;
  currentPage: number;
  closedCases: boolean;
  searchQuery: string;
  totalPages: number;
}

const defaultCasesSetting = {
  closedCases: false,
  currentPage: ZERO,
  sort: 'sort=createdAt,DESC',
  searchQuery: '',
  totalPages: ONE,
};

export interface CaseState {
  // Currently, active case that is used in a flow
  activeCaseData: ICase | undefined;
  // List of cases uses as a hot cache
  cases: Array<ICase>;
  casesSettings: ICasesSetting;
}

export enum CaseGetters {
  ACTIVE_CASE = 'case/getActiveCase',
  CASES = 'case/cases',
  CASES_SETTINGS = 'case/casesSettings',
  PROPERTY_BY_ID = 'case/propertyById',
}

export enum CaseMutations {
  SET_ACTIVE_CASE = 'case/setActiveCaseData',
}

export enum CaseActions {
  FETCH_CASE_BY_ID = 'case/fetchCaseById',
  FETCH_CASES = 'case/fetchCases',
}

/**
 * Case Store
 *
 * Used to store case related information.
 */
const caseStore: Module<CaseState, RootState> = {
  namespaced: true,

  state: {
    activeCaseData: undefined,
    cases: [],
    casesSettings: defaultCasesSetting,
  },

  mutations: {
    setActiveCaseData(state, caseData) {
      state.activeCaseData = caseData;
    },
    setCases(state, cases) {
      state.cases = cases;
    },
    setCasesSettings(state, settings) {
      state.casesSettings = settings;
    },
  },

  actions: {
    async fetchCaseById({ commit }, caseId: number) {
      const caseResponse = await getCaseById(caseId);
      commit('setActiveCaseData', caseResponse.data);
    },
    async fetchCases({ commit, state }, casesSettings?: ICasesSetting) {
      const setting = casesSettings ?? state.casesSettings;
      const response = await getAllCases(!setting.closedCases, setting.currentPage, setting.sort, setting.searchQuery);
      commit('setCases', response.data.content);
      commit('setCasesSettings', {
        ...setting,
        currentPage: response.data.number,
        totalPages: response.data.totalPages,
      });
    },
  },

  getters: {
    getActiveCase: (state) => state.activeCaseData,
    cases: (state) => state.cases,
    casesSettings: (state) => state.casesSettings,
    propertyById: (state) => (propertyId: number) =>
      state.activeCaseData?.properties.find((p: IProperty) => p.id === propertyId),
  },
};

export default caseStore;
