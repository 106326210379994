<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="fill: currentColor"
  >
    <path
      d="M5.04127 2.10797L5.04099 2.10809C2.52577 3.25945 0.9 5.78215 0.9 8.53571C0.9 12.4317 4.08556 15.6 8 15.6C11.9144 15.6 15.1 12.4317 15.1 8.53571C15.1 5.78215 13.4742 3.25944 10.959 2.10836L10.9589 2.10832C10.6376 1.96164 10.2595 2.10105 10.1127 2.42C9.96455 2.73953 10.1052 3.11694 10.4256 3.2636L10.4256 3.26361C12.4895 4.20797 13.8231 6.27795 13.8231 8.53571C13.8231 11.7294 11.2113 14.3286 8 14.3286C4.78871 14.3286 2.17692 11.7294 2.17692 8.53571C2.17692 6.27795 3.51053 4.20797 5.57439 3.26334L5.57443 3.26332C5.89436 3.1167 6.03542 2.73948 5.88743 2.41993C5.7406 2.10135 5.36278 1.96197 5.04127 2.10797ZM8.63846 8.53571V1.03571C8.63846 0.684322 8.35228 0.4 8 0.4C7.64772 0.4 7.36154 0.684322 7.36154 1.03571V8.53571C7.36154 8.88711 7.64772 9.17143 8 9.17143C8.35228 9.17143 8.63846 8.88711 8.63846 8.53571Z"
      stroke-width="0.2"
    />
  </svg>
</template>
