import httpClient from '@/http';
import { AxiosPromise } from 'axios';
import { authPlugin } from '@/services/auth';

/**
 *
 * @param {string} caseNumber
 * @returns {Map<VALIDATION_TYPES, ResponseCheckModel>}
 */

export async function getValidationCheck(caseNumber: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return httpClient({
    method: 'GET',
    url: `/application/validation/v1/validation/${caseNumber}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
/**
 *
 * @param {number} checkId
 * @returns {Promise<AxiosPromise>}
 */
export async function validatedCheck(checkId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return httpClient({
    method: 'PUT',
    url: `/application/validation/v1/validation/${checkId}/validate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
/**
 *
 * @param {number} validationId
 * @param {string} reworkComment
 * @returns {Promise<AxiosPromise>}
 */
export async function rejectCheck(validationId: number, reworkComment: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return httpClient({
    method: 'PUT',
    url: `/application/validation/v1/validation/${validationId}/reject`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      reworkComment,
    },
  });
}
