import { IAddress } from '@/types/IAddress';

export interface AddressProps {
  country: string;
  town: string;
  streetName: string;
  houseNumber: string;
  houseName: string;
  postcode: string;
  flatNumber: string;
  floorNumber: string;
  blockNumber: string;
  formattedAddress: string;
}

class Address implements IAddress {
  country: string;
  town: string;
  streetName: string;
  houseNumber: string;
  houseName: string;
  postcode: string;
  flatNumber: string;
  floorNumber: string;
  blockNumber: string;
  formattedAddress: string;

  constructor({
    country,
    town,
    streetName,
    houseNumber,
    houseName,
    postcode,
    flatNumber,
    floorNumber,
    blockNumber,
    formattedAddress,
  }: AddressProps) {
    this.country = country;
    this.town = town;
    this.streetName = streetName;
    this.houseNumber = houseNumber;
    this.houseName = houseName;
    this.postcode = postcode;
    this.flatNumber = flatNumber;
    this.floorNumber = floorNumber;
    this.blockNumber = blockNumber;
    this.formattedAddress = formattedAddress;
  }

  /**
   * Method uses values populated in instance to generate a address that is used to display for the user.
   * Note: Order of fields might need to be reviewed
   */
  assembleRequiredFilesForDisplay(): string {
    return `${this.flatNumber ?? ''} ${this.houseNumber ?? ''} ${this.houseName ?? ''} ${this.streetName}, ${
      this.town
    }, ${this.postcode}`;
  }

  static init(): IAddress {
    return new Address({
      country: 'GBR', // In our case always Great Britain
      town: '',
      streetName: '',
      houseNumber: '',
      houseName: '',
      postcode: '',
      flatNumber: '',
      blockNumber: '',
      floorNumber: '',
      formattedAddress: '',
    });
  }
}

export default Address;
