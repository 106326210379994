export const MIN_NUMBER_OF_TENANTS = 3;
export const MIN_NUMBER_OF_TENANTS_LARGE = 5;
export const MIN_NUMBER_OF_HOUSEHOLDS = 2;
export const MAX_NUMBER_OF_PEOPLE = 15;

// TODO move to folder types
export enum VALIDATION_TYPES {
  ID_AND_V = 'ID_AND_V',
  KYC_IDENTITY = 'KYC_IDENTITY',
  KYC_PEPS = 'KYC_PEPS',
  PERSONAL_INCOME_CHECK = 'PERSONAL_INCOME_CHECK',
  PROOF_OF_RESIDENCY = 'PROOF_OF_RESIDENCY',
  BANK_ACCOUNT_VALIDATION = 'BANK_ACCOUNT_VALIDATION',
  ACTUAL_RENTAL_INCOME = 'ACTUAL_RENTAL_INCOME',
  PROOF_OF_AST = 'PROOF_OF_AST',
  PORTFOLIO_LANDLORD_INFORMATION = 'PORTFOLIO_LANDLORD_INFORMATION',
  LAND_REGISTRY_DOCUMENTS = 'LAND_REGISTRY_DOCUMENTS',
  MORTGAGE_CHECKS_AND_DUES = 'MORTGAGE_CHECKS_AND_DUES',
  REPORT_ON_TITLE = 'REPORT_ON_TITLE',
  NOTICE_OF_CORRECTIONS = 'NOTICE_OF_CORRECTIONS',
  FRAUD_DETECT = 'FRAUD_DETECT',
  AGE = 'AGE',
  PROPERTY_VALUATION = 'PROPERTY_VALUATION',
  HMO_DETAILS = 'HMO_DETAILS',
}
// TODO move to folder types
export enum VALIDATION_STATUSES {
  VALIDATED = 'VALIDATED', // Validated automatically - the rules match
  VALIDATED_MANUALLY = 'VALIDATED_MANUALLY', // Validated manually by underwriter
  NOT_VALIDATED = 'NOT_VALIDATED', // Not validated - the rules don't match, auto service could not do it
  PENDING_DOCUMENT = 'PENDING_DOCUMENT', // Awaiting documents upload, broker needs to upload, UW does nothing
  PENDING_MANUAL_VALIDATION = 'PENDING_MANUAL_VALIDATION', // Awaiting manual approval from UW
  REJECTED = 'REJECTED', // Rejected after auto validations // ASSUMING THIS WILL NOT HAPPEN
  REJECTED_MANUALLY = 'REJECTED_MANUALLY', // Rejected manually
  FAILED = 'FAILED', // Validation failed for several errors (network, 3rd party service, ...)

  // Special non-generic types primarily used to indicate External service handling
  NOT_VALIDATED_OPEN_BANKING = 'NOT_VALIDATED_OPEN_BANKING',
  NOT_VALIDATED_ONFIDO = 'NOT_VALIDATED_ONFIDO',
  PENDING_OPEN_BANKING = 'PENDING_OPEN_BANKING',
  PENDING_ONFIDO = 'PENDING_ONFIDO',
  VALIDATED_ONFIDO = 'VALIDATED_ONFIDO',
  VALIDATED_OPEN_BANKING = 'VALIDATED_OPEN_BANKING',
}

// Computed keys are not allowed in enums so using object
export const stateLabelMapper = {
  [VALIDATION_STATUSES.NOT_VALIDATED]: '',
  [VALIDATION_STATUSES.PENDING_DOCUMENT]: '',
  [VALIDATION_STATUSES.PENDING_MANUAL_VALIDATION]: '',
  [VALIDATION_STATUSES.FAILED]: '',
  [VALIDATION_STATUSES.NOT_VALIDATED_OPEN_BANKING]: '',
  [VALIDATION_STATUSES.NOT_VALIDATED_ONFIDO]: '',
  [VALIDATION_STATUSES.VALIDATED]: 'Approved', // When service automatically approves it
  [VALIDATION_STATUSES.VALIDATED_ONFIDO]: 'Approved',
  [VALIDATION_STATUSES.VALIDATED_OPEN_BANKING]: 'Approved',
  [VALIDATION_STATUSES.VALIDATED_MANUALLY]: 'Approved', // When UW approves it
  [VALIDATION_STATUSES.REJECTED]: 'Rejected',
  [VALIDATION_STATUSES.REJECTED_MANUALLY]: 'Action requested',
  [VALIDATION_STATUSES.PENDING_OPEN_BANKING]: 'Open Banking in progress',
  [VALIDATION_STATUSES.PENDING_ONFIDO]: 'Onfido in progress',
};

// FIXME this is not great
export const stateLabelMapperSummary = {
  [VALIDATION_STATUSES.NOT_VALIDATED]: 'Not validated',
  [VALIDATION_STATUSES.PENDING_DOCUMENT]: 'Pending document',
  [VALIDATION_STATUSES.PENDING_MANUAL_VALIDATION]: 'Pending manual validation',
  [VALIDATION_STATUSES.FAILED]: 'Failed',
  [VALIDATION_STATUSES.VALIDATED]: 'Approved',
  [VALIDATION_STATUSES.VALIDATED_ONFIDO]: 'Approved',
  [VALIDATION_STATUSES.VALIDATED_OPEN_BANKING]: 'Approved',
  [VALIDATION_STATUSES.VALIDATED_MANUALLY]: 'Approved',
  [VALIDATION_STATUSES.REJECTED]: 'Rejected',
  [VALIDATION_STATUSES.REJECTED_MANUALLY]: 'Action requested',
  [VALIDATION_STATUSES.PENDING_OPEN_BANKING]: 'Open Banking in progress',
  [VALIDATION_STATUSES.PENDING_ONFIDO]: 'Onfido in progress',
};

export const validationsForCompany = [
  VALIDATION_TYPES.BANK_ACCOUNT_VALIDATION,
  VALIDATION_TYPES.PROPERTY_VALUATION,
  VALIDATION_TYPES.ACTUAL_RENTAL_INCOME,
  VALIDATION_TYPES.PROOF_OF_AST,
  VALIDATION_TYPES.PORTFOLIO_LANDLORD_INFORMATION,
  VALIDATION_TYPES.LAND_REGISTRY_DOCUMENTS,
  VALIDATION_TYPES.MORTGAGE_CHECKS_AND_DUES,
  VALIDATION_TYPES.REPORT_ON_TITLE,
];

export const validationsOnlyForUW = [
  VALIDATION_TYPES.KYC_PEPS,
  VALIDATION_TYPES.FRAUD_DETECT,
  VALIDATION_TYPES.NOTICE_OF_CORRECTIONS,
];

export const typeLabelMapper = {
  [VALIDATION_TYPES.ID_AND_V]: 'ID & V',
  [VALIDATION_TYPES.KYC_IDENTITY]: 'KYC',
  [VALIDATION_TYPES.KYC_PEPS]: 'KYC PEPs & Sanctions',
  [VALIDATION_TYPES.FRAUD_DETECT]: 'Fraud detect',
  [VALIDATION_TYPES.NOTICE_OF_CORRECTIONS]: 'Notice of corrections',
  [VALIDATION_TYPES.PERSONAL_INCOME_CHECK]: 'Personal Income',
  [VALIDATION_TYPES.PROOF_OF_RESIDENCY]: 'Proof of Residency',
  [VALIDATION_TYPES.BANK_ACCOUNT_VALIDATION]: 'Bank Account Ownership',
  [VALIDATION_TYPES.PROPERTY_VALUATION]: 'Security Property Values',
  [VALIDATION_TYPES.ACTUAL_RENTAL_INCOME]: 'Actual Rental Check',
  [VALIDATION_TYPES.PROOF_OF_AST]: 'Proof of AST',
  [VALIDATION_TYPES.PORTFOLIO_LANDLORD_INFORMATION]: 'Portfolio Information',
  [VALIDATION_TYPES.LAND_REGISTRY_DOCUMENTS]: 'Land Registry Documents',
  [VALIDATION_TYPES.MORTGAGE_CHECKS_AND_DUES]: 'Mortgage Checks',
  [VALIDATION_TYPES.REPORT_ON_TITLE]: 'Report on Title',
  [VALIDATION_TYPES.AGE]: 'Age',
  [VALIDATION_TYPES.HMO_DETAILS]: 'HMO Details',
};

export enum ValidationSections {
  Borrower = 'Borrower',
  SecurityProperty = 'SecurityProperty',
  Mortgage = 'Mortgage',
}

export const ValidationSectionGroupMapper: Record<ValidationSections, Array<VALIDATION_TYPES>> = {
  [ValidationSections.Borrower]: [
    VALIDATION_TYPES.ID_AND_V,
    VALIDATION_TYPES.KYC_IDENTITY,
    VALIDATION_TYPES.KYC_PEPS,
    VALIDATION_TYPES.PERSONAL_INCOME_CHECK,
    VALIDATION_TYPES.PROOF_OF_RESIDENCY,
    VALIDATION_TYPES.BANK_ACCOUNT_VALIDATION,
    VALIDATION_TYPES.NOTICE_OF_CORRECTIONS,
    VALIDATION_TYPES.FRAUD_DETECT,
    VALIDATION_TYPES.PORTFOLIO_LANDLORD_INFORMATION,
  ],
  [ValidationSections.SecurityProperty]: [
    VALIDATION_TYPES.PROPERTY_VALUATION,
    VALIDATION_TYPES.ACTUAL_RENTAL_INCOME,
    VALIDATION_TYPES.PROOF_OF_AST,
    VALIDATION_TYPES.LAND_REGISTRY_DOCUMENTS,
    VALIDATION_TYPES.HMO_DETAILS,
  ],
  [ValidationSections.Mortgage]: [VALIDATION_TYPES.MORTGAGE_CHECKS_AND_DUES, VALIDATION_TYPES.REPORT_ON_TITLE],
};

// TODO move to folder types
export type DocumentInfo = {
  documentId: number;
  filename: string;
  creationDate: string;
};

// TODO move to folder types
export type ResponseCheckModel = {
  id: number;
  createdAt: string;
  lastUpdatedAt: string;
  loanCaseId: number;
  borrowerId: number;
  companyNumber: number;
  propertyId: number;
  type: VALIDATION_TYPES;
  status: VALIDATION_STATUSES;
  closedDate: string | null;
  rejectionComment: string | null;
  details: Record<string, string | number>;
  documents: Array<DocumentInfo>;
};
