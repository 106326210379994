export default {
  // Generic routes
  HOME: 'home',
  DASHBOARD: 'dashboard',
  QUICK_QUOTES: 'quick-quotes',

  // Pricing configuration routes
  PRICING_CONFIGURATION: 'pricing-configuration',
  APPROVAL_QUEUE: 'approval-queue',
  APPROVE_PRICING_CONFIGURATION: 'approve-pricing-configuration',

  // Main flow routes.
  APPLICATION: 'application',
  VALIDATIONS: 'validations',
  CASE_SUMMARY: 'case-summary',
  BORROWER_NOT_FOUND: 'borrower-not-found',
  BORROWER_NOT_SERVICEABLE: 'borrower-not-serviceable',
  INDICATIVE_QUOTES: 'indicative-quotes',
  NEW_CASE_MODAL: 'new-case-modal',
  CONDITIONAL_OFFER: 'conditional-offer',
  DISBURSAL: 'disbursal',
  PRE_APPLICATION: 'pre-application',
  NOT_ELIGIBLE: 'not-eligible',
  LOGOUT: 'logout',

  SPEAK_WITH: 'speak-with',

  // Legal routes
  LEGALS: 'legals',
  LEGALS_UPLOAD: 'legals-upload',
  CONFIRM_MANUAL_PACKAGE: 'confirm-manual-package',
};
