import routeNames from '@/router/routeNames';
import { ICaseAccess } from '@/router/guards/case/caseGuard';

export class UnderwriterCaseAccess implements ICaseAccess {
  hasAccess(): boolean {
    return true;
  }

  redirectTo(): string {
    return routeNames.DASHBOARD;
  }
}
