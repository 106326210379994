import HttpClient from '@/http/index';
import { authPlugin } from '@/services/auth';
import { AxiosPromise } from 'axios';
import { ZERO } from '@/constants';

export async function getDocumentByType(docId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'GET',
    responseType: 'blob',
    url: `/application/legals/v1/documents/${docId}/draft`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAllDocuments(loanCaseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'GET',
    url: `/application/legals/v1/documents`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      loanCaseId,
    },
  });
}

export async function getSingleDocument(docId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'GET',
    responseType: 'blob',
    url: `/application/legals/v1/documents/${docId}/signed`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function uploadDoc(docId: number, fileReference: HTMLFormElement): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  const data = new FormData();
  data.append('file', fileReference.files[ZERO]);

  return HttpClient({
    method: 'PUT',
    url: `/application/legals/v1/documents/${docId}/signed`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}

export async function deleteSignedDoc(docId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'DELETE',
    url: `/application/legals/v1/documents/${docId}/signed`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function requestSignature(loanCaseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'POST',
    url: `/application/legals/v1/documents/eSign`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      loanCaseId,
    },
  });
}

export async function downloadDocuments(loanCaseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();
  return HttpClient({
    method: 'GET',
    responseType: 'blob',
    url: `/application/legals/v1/documents/prints`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      loanCaseId,
    },
  });
}
