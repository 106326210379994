import { createApp } from 'vue';
import App from './App.vue';

/**
 * Vue Router
 */
import router from './router';

/**
 * Vuex Store
 */
import store from './store';

/**
 * Custom Auth0 wrapper
 */
import { Auth, CallbackAppState } from '@/services/auth';

/**
 * Sentry packages
 */
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import { createGtm } from '@gtm-support/vue-gtm';
import mitt from 'mitt';

/**
 * Directives
 */
import clickOutside from '@/directives/click-outside';

/**
 * Global event bus
 */
const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.$eventBus = emitter;

// Interceptors
import('./interceptors/sentry-interceptor');

// Global type extensions

declare global {
  interface Window {
    // TODO: Soo, I am unable to find any source code for the code we are importing from Hotjar as it is pulled dynamically
    //       and I cannot determine the types of the hj object it is injecting into the window object.
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    hj: any;
    _hjSettings: any;
  }
}

/**
 * Method used to bootstrap the application and initialise all necessary packages and dependencies.
 *
 * @returns {Promise<void>}
 */
async function init() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      app,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: process.env.VUE_APP_SENTRY_ENV,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['https://dev.scrollfinance.cloud', 'https://staging.scrollfinance.cloud/', /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  } else {
    Sentry.init({
      app,
      defaultIntegrations: false,
    });
  }

  // Directives
  app.directive('click-outside', clickOutside);

  app.use(
    createGtm({
      id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      queryParams: {
        // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
        gtm_auth: process.env.VUE_APP_GTM_AUTH,
        gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
        gtm_cookies_win: process.env.VUE_APP_GTM_COOKIES_WIN,
      },
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      nonce: '2726c7f26c', // Will add `nonce` to the script tag
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether display console logs debugs (optional)
      loadScript: true, // Whether to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
      trackOnNextTick: false, // Whether call trackView in Vue.nextTick
    }),
  );

  const AuthPlugin = await Auth.init({
    onRedirectCallback: (appState: CallbackAppState) => {
      router.push(appState?.targetUrl ?? window.location.pathname);
    },
    clientId: process.env.VUE_APP_AUTH0_CLIENT_KEY,
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    redirectUri: window.location.origin,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  });

  if (process.env.VUE_APP_HOTJAR_ENABLED) {
    // Following blob of code is generated by Hotjar so not modifying it
    /* eslint-disable */
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: process.env.VUE_APP_HOTJAR_ID, hjsv: process.env.VUE_APP_HOTJAR_SNIPPET_VERSION };
      // @ts-ignore
      a = o.getElementsByTagName('head')[0];
      // @ts-ignore
      r = o.createElement('script');
      // @ts-ignore
      r.async = 1;
      // @ts-ignore
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      // @ts-ignore
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    /* eslint-enable */
  }

  app.use(store).use(router).use(AuthPlugin).mount('#app');
}

init();
