import { Module } from 'vuex';
import { RootState } from '@/store';
import { ICase } from '@/types/ICase';
import { CaseGetters } from '@/store/modules/case';
import { getAllDocuments } from '@/http/Legals';
import { UWDocument } from '@/types/IUWDocument';

export interface LegalsState {
  legalsDocuments: UWDocument | null;
}

export enum LegalGetters {
  LEGALS_DOCUMENTS = 'legal/legalsDocuments',
}

export enum LegalActions {
  FETCH_LEGALS_DOCUMENTS = 'legal/fetchLegalsDocuments',
}

const legalsStore: Module<LegalsState, RootState> = {
  namespaced: true,

  state: {
    legalsDocuments: null,
  },

  mutations: {
    setLegalsDocuments(state, legalsDocuments) {
      state.legalsDocuments = legalsDocuments;
    },
  },

  actions: {
    async fetchLegalsDocuments({ commit, rootGetters }) {
      const caseLoan: ICase = rootGetters[CaseGetters.ACTIVE_CASE];
      const documents = (await getAllDocuments(caseLoan.number)).data;
      commit('setLegalsDocuments', documents);
    },
  },

  getters: {
    legalsDocuments: (state) => state.legalsDocuments,
  },
};

export default legalsStore;
