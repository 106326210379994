// Anti magic-numbers
export const NEGATIVE_ONE = -1;
export const NOT_FOUND_INDEX = -1;
export const ZERO = 0;
export const FIRST_INDEX = 0;
export const SECOND_INDEX = 1;
export const ONE = 1;
export const TWO = 2;
export const SIX = 6;
export const ONE_HUNDRED = 100;
export const ONE_THOUSAND = 1000;
export const ONE_SECOND_MS = 1000;
export const ONE_MINUTE_SECONDS = 60;
export const ONE_HOUR_SECONDS = 3600;
export const ONE_DAY_HOURS = 24;
export const TWO_DAYS_HOURS = 48;
export const ONE_YEAR_DAYS = 365;
export const ONE_YEAR_MONTHS = 12;
export const BYTES = 1024;
export const REFRESH_PAGE = 0;
// APPLICATION SETTINGS:
export const ALERT_TIMEOUT = 7000;
export const ADDRESS_WHISPERER_DEBOUNCE_TIME = 300;
export const LTV_DEBOUNCE_TIME = 500;
// Dashboard
export const CASES_PER_PAGE = 20;
// PreApplication
export const MIN_SHAREHOLDING_PERCENTAGE = 10;
// Application
export const INPUT_MIN_LENGTH = 2;
export const BORROWER_MIN_AGE = 21;
export const CO_BORROWER_MIN_AGE = 18;
export const BORROWER_MAX_AGE = 82;
export const BORROWER_MAX_AGE_END_OF_TERM = 85;
export const MAX_BEDROOMS = 10;
export const RESET_MAX_YEARS = 10;
export const PROPERTY_MIN_VALUE = 75000;
export const PROPERTY_MAX_VALUE = 99999999;
export const LOAN_TERM_MIN_YEARS = 3;
export const LOAN_TERM_MAX_YEARS = 25;
export const NET_LOAN_MIN_FOR_QUICK_QUOTE = 10000;
export const NET_LOAN_MIN = 25000;
export const NET_LOAN_MAX = 50000;
export const BROKER_FEE_MAX = 6000;
export const BROKER_FEE_MAX_PERCENTAGE = 12.5;
export const LTV_MAX_RATIO = 0.8;
export const LTV_PORTFOLIO_LANDLORD_MAX_RATIO = 0.75;
export const LTV_MSP_MAX_RATIO = 0.75;
export const HEL_PRODUCT_FEE_PERCENT = 2;
export const HELOC_PRODUCT_FEE_PERCENT = 2.5;
export const MAX_PROPERTIES = 10;
// Case Summary
export const QUOTE_LOADER_DELAY = 5000;
// Indicative Quotes
export const QUOTE_EXPIRATION_DAYS = 7;
export const CUSTOM_OPTION_MINIMUM_YEARS = 1;
export const CUSTOM_OPTION_MAXIMUM_YEARS = 5;
// Conditonal Offer
export const OFFER_EXPIRATION_DAYS = 30;
// Bank Account Validation
export const SORT_CODE_LENGTH = 8;
export const ACCOUNT_NUMBER_LENGTH = 8;
// Validations
export const MIN_YEARS_LEASEHOLD = 85;
export const MIN_YEARS_LEASEHOLD_AT_END = 65;
export const DEFAULT_MAX_FILE = 3;
// Pricing configuration
export const DEFAULT_SIZE_PRICING_CONFIGURATION = 10;

// HTTP_STATUS_CODES
export const HTTP_OK = 200;

export const NO_CONTENT = 204;
