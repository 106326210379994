import getHttpClientWithAuth from './getHttpClientWithAuth';
import { AxiosPromise } from 'axios';
import {
  IGetApprovalQueueProps,
  IGetApprovalQueueResponse,
  IPricingConfiguration,
  IPricingConfigurationRequest,
  IPricingConfigurationVersion,
} from '@/types/IPricingConfiguration';
import { SORT } from '@/types/ISortBy';
import { IAdminChangeRecord } from '@/types/Admin';

export async function getCurrentPricingConfiguration(): Promise<AxiosPromise<IPricingConfiguration>> {
  const client = await getHttpClientWithAuth();
  return client.get(`/config/api/v1/pricing/current`);
}

export async function getPricingConfigurationById(id: number): Promise<AxiosPromise<IPricingConfiguration>> {
  const client = await getHttpClientWithAuth();
  return client.get(`/config/api/v1/pricing/${id}`);
}

export async function createPricingConfiguration(
  data: IPricingConfigurationRequest,
): Promise<AxiosPromise<IPricingConfiguration>> {
  const client = await getHttpClientWithAuth();
  return client.post('/config/api/v1/pricing', data);
}

export async function getPricingConfigurationVersions(): Promise<AxiosPromise<IPricingConfigurationVersion>> {
  const client = await getHttpClientWithAuth();
  return client.get(`/config/api/v1/pricing/versions`);
}

export async function getApprovalQueue(
  payload: IGetApprovalQueueProps,
): Promise<AxiosPromise<IGetApprovalQueueResponse>> {
  const params = new URLSearchParams();
  params.append('size', String(payload.size));
  params.append('page', String(payload.page));
  if (payload.sort.direction !== SORT.NONE) {
    params.append('sort', `${payload.sort.fieldName},${payload.sort.direction}`);
  }
  payload.statuses.forEach((status) => {
    params.append('statuses', status);
  });

  const client = await getHttpClientWithAuth();
  return client.get(`/config/api/v1/pricing`, {
    params,
  });
}

export async function getApproveDetail(idPricingConfiguration: number): Promise<AxiosPromise<IAdminChangeRecord>> {
  const client = await getHttpClientWithAuth();
  return client.get(`/config/api/v1/pricing/approval-queue/${idPricingConfiguration}`);
}

export async function approvePricingConfiguration(id: number, validFrom: string) {
  const client = await getHttpClientWithAuth();
  return client.put(`/config/api/v1/pricing/${id}/approve`, {
    validFrom,
  });
}

export async function rejectPricingConfiguration(id: number) {
  const client = await getHttpClientWithAuth();
  return client.put(`/config/api/v1/pricing/${id}/reject`);
}
