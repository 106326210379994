import { NavigationGuardWithThis, RouteLocationNormalized } from 'vue-router';
import routeNames from '@/router/routeNames';
import { authPlugin } from '@/services/auth';
import { ICase } from '@/types/ICase';
import { BrokerCaseAccess } from '@/router/guards/case/brokerAccess';

import store from '@/store';
import { UnderwriterCaseAccess } from '@/router/guards/case/underwriteAccess';
import { useAlertService } from '@/services/AlertService';
import { TOASTBAR_TYPES } from '@/components/common/ToastBar.vue';
import { CaseActions, CaseGetters } from '@/store/modules/case';

export interface ICaseAccess {
  hasAccess(activeCase: ICase, to: RouteLocationNormalized): boolean;
  redirectTo(): string;
}

const getUserCaseAccess = (): ICaseAccess | null => {
  const { isUserBroker, isUserUnderwriter } = authPlugin;
  if (isUserBroker()) {
    return new BrokerCaseAccess();
  }
  if (isUserUnderwriter()) {
    return new UnderwriterCaseAccess();
  }
  return null;
};

export const CaseGuard: NavigationGuardWithThis<undefined> = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: (redirectTo?: string) => void,
) => {
  const { caseNumber } = to.query;
  const { showAlert } = useAlertService();

  if (caseNumber) {
    await store.dispatch(CaseActions.FETCH_CASE_BY_ID, Number(to.query.caseNumber));
    const activeCase: ICase = await store.getters[CaseGetters.ACTIVE_CASE];

    const caseAccess = getUserCaseAccess();
    if (caseAccess === null) {
      // User role doesn't have implementation regarding accessing. User will be redirected to dashboard
      console.warn('For this role is not implemented CaseAccess');
      next(routeNames.DASHBOARD);
    } else if (caseAccess.hasAccess(activeCase, to)) {
      // User has access on the page and will continue
      next();
    } else {
      // Role hasn't access on page, and he will be redirected
      const redirectRouteName = caseAccess.redirectTo();
      showAlert('Sorry, you don’t have access to this page.', TOASTBAR_TYPES.Negative);
      next(redirectRouteName);
    }
  } else {
    console.warn('Missing caseNumber in query.');
    next(routeNames.DASHBOARD);
  }
};
