import { NavigationGuardWithThis, RouteLocationNormalized } from 'vue-router';
import { hasAccessOnRoute, redirectTo } from '@/router/guards/auth/roleAccess';
import { watchEffect } from 'vue';
import { authPlugin } from '@/services/auth';

export const AuthGuard: NavigationGuardWithThis<undefined> = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: (redirectTo?: string) => void,
  // eslint-disable-next-line consistent-return
) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { isAuthenticated, getUserRole, loading, loginWithRedirect } = authPlugin;

  // eslint-disable-next-line consistent-return
  const verify = async () => {
    // If the user is authenticated, continue with the route
    if (isAuthenticated.value) {
      if (!hasAccessOnRoute(to, getUserRole())) {
        const redirectRouteName = redirectTo(getUserRole());
        return next(redirectRouteName);
      }
      return next();
    }

    // Otherwise, log in
    await loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  // eslint-disable-next-line consistent-return
  watchEffect(() => {
    if (!loading.value) {
      return verify();
    }
  });
};
