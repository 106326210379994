import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import routeNames from '@/router/routeNames';
import { AuthGuard } from '@/router/guards/auth/authGuard';
import { CaseGuard } from '@/router/guards/case/caseGuard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: routeNames.DASHBOARD,
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter: AuthGuard,
  },
  {
    path: '/quick-quotes',
    name: routeNames.QUICK_QUOTES,
    component: () => import('@/views/QuickQuotes.vue'),
    beforeEnter: AuthGuard,
  },
  {
    path: '/application',
    name: routeNames.APPLICATION,
    component: () => import('@/views/individualBorrower/Application.vue'),
    beforeEnter: [AuthGuard, CaseGuard],
  },
  {
    path: '/validations',
    name: routeNames.VALIDATIONS,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/views/validations/Validations.vue'),
  },
  {
    path: '/speak-with',
    name: routeNames.SPEAK_WITH,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/views/SpeakWith.vue'),
  },
  {
    path: '/legals',
    name: routeNames.LEGALS,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/views/legals/Legals.vue'),
  },
  {
    path: '/legals-upload',
    name: routeNames.LEGALS_UPLOAD,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/views/legals/ActiveSetupLegals.vue'),
  },
  {
    path: '/confirm-manual-package',
    name: routeNames.CONFIRM_MANUAL_PACKAGE,
    beforeEnter: AuthGuard,
    component: () => import('@/views/legals/ConfirmManualPackage.vue'),
  },
  {
    path: '/case-summary',
    name: routeNames.CASE_SUMMARY,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/views/CaseSummary.vue'),
  },
  {
    path: '/indicative-quotes',
    name: routeNames.INDICATIVE_QUOTES,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/views/IndicativeQuotes.vue'),
  },
  {
    path: '/conditional-offer',
    name: routeNames.CONDITIONAL_OFFER,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/views/IndicativeQuotes/ConditionalOffer.vue'),
  },
  {
    path: '/borrower-not-found',
    name: routeNames.BORROWER_NOT_FOUND,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/components/caseSummary/BorrowerNotFound.vue'),
  },
  {
    path: '/borrower-not-serviceable',
    name: routeNames.BORROWER_NOT_SERVICEABLE,
    beforeEnter: AuthGuard,
    component: () => import('@/views/IndicativeQuotes/BorrowerNotServiceable.vue'),
  },
  {
    path: '/disbursal',
    name: routeNames.DISBURSAL,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/views/disbursal/Disbursal.vue'),
  },
  {
    path: '/pre-application',
    name: routeNames.PRE_APPLICATION,
    beforeEnter: [AuthGuard, CaseGuard],
    component: () => import('@/views/PreApplication.vue'),
  },
  {
    path: '/not-eligible',
    name: routeNames.NOT_ELIGIBLE,
    beforeEnter: AuthGuard,
    component: () => import('@/views/company/CompanyNotEligible.vue'),
  },
  {
    path: '/logout',
    name: routeNames.LOGOUT,
    beforeEnter: AuthGuard,
    component: () => import('@/views/Logout.vue'),
  },
  {
    path: '/pricing-configuration',
    name: routeNames.PRICING_CONFIGURATION,
    beforeEnter: AuthGuard,
    component: () => import('@/views/PricingConfiguration.vue'),
  },
  {
    path: '/approval-queue',
    name: routeNames.APPROVAL_QUEUE,
    beforeEnter: AuthGuard,
    component: () => import('@/views/ApprovalQueue.vue'),
  },
  {
    path: '/approval-queue/:id',
    name: routeNames.APPROVE_PRICING_CONFIGURATION,
    beforeEnter: AuthGuard,
    component: () => import('@/views/ApprovePricingConfiguration.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
