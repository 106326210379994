import { Module } from 'vuex';
import { IUser } from '@/types/IUser';
import User from '@/models/User';

export interface IUserStore {
  userData: IUser;
}

/**
 * User Store
 *
 * Used to store personal or user related information about user.
 */
const userStore: Module<IUserStore, unknown> = {
  namespaced: true,
  state: {
    userData: new User(),
  },
  mutations: {
    setUserData(state, payload: IUser) {
      state.userData = payload;
    },
  },
  actions: {
    setUserData({ commit }, payload: IUser) {
      commit('setUserData', payload);
    },
  },
};

export default userStore;
