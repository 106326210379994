import INotification, { INotificationSection, INotificationsGroup } from '@/types/INotificationCase';
import { FIRST_INDEX, HTTP_OK, ONE_HUNDRED } from '@/constants';
import getHttpClientWithAuth from '@/http/getHttpClientWithAuth';

export enum NotificationsAgeEnum {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  OLDER = 'OLDER',
}

export type TGetNotificationsGroup = { [caseNumber: string]: Array<INotification> };

export type TGetNotificationsByAge = Partial<Record<NotificationsAgeEnum, TGetNotificationsGroup>>;

export interface IGetNotificationsResponse {
  notifications: TGetNotificationsByAge;
  pageable: {
    pageNumber: number;
  };
}

export const NO_NOTIFICATIONS_COUNT = 0;
const DEFAULT_PAGE_SIZE = 20;

interface IGetNotificationsQuery {
  sort?: string;
  size?: number;
  page?: number;
  showOnlyCritical: boolean;
}

const AGE_ORDER = {
  [NotificationsAgeEnum.TODAY]: 0,
  [NotificationsAgeEnum.YESTERDAY]: 1,
  [NotificationsAgeEnum.OLDER]: 2,
};

export async function getNotifications({
  sort = 'createdAt,desc',
  size = DEFAULT_PAGE_SIZE,
  page = FIRST_INDEX,
  showOnlyCritical = false,
}: IGetNotificationsQuery): Promise<Array<INotificationSection>> {
  const HttpClient = await getHttpClientWithAuth();
  const {
    data: { notifications: serverNotifications },
  } = await HttpClient.get<IGetNotificationsResponse>('/notification/v1/notifications', {
    params: {
      sort,
      size,
      page,
      showOnlyCritical,
    },
  });

  // Sort notifications manually, because in ES sorting is not guaranteed
  return Object.entries(serverNotifications)
    .map(
      ([age, serverGroup]) =>
        ({
          age: age as NotificationsAgeEnum,
          groups: Object.entries(serverGroup)
            .map(
              ([key, caseNotifications]) =>
                ({
                  groupKey: key,
                  notifications: caseNotifications,
                } as INotificationsGroup),
            )
            .sort((a, b) =>
              b.notifications[FIRST_INDEX].createdAt.localeCompare(a.notifications[FIRST_INDEX].createdAt),
            ),
        } as INotificationSection),
    )
    .sort((a, b) => (AGE_ORDER[a.age] ?? ONE_HUNDRED) - (AGE_ORDER[b.age] ?? ONE_HUNDRED));
}

interface IUnreadCountResponse {
  count: number;
}

export async function getUnreadCount(): Promise<IUnreadCountResponse> {
  const HttpClient = await getHttpClientWithAuth();
  const response = await HttpClient.get<IUnreadCountResponse>('/notification/v1/notifications/countUnread');

  return response.data;
}

export async function putToggleReadStatus(ids: Array<number>): Promise<Array<INotification> | null> {
  try {
    const HttpClient = await getHttpClientWithAuth();
    const response = await HttpClient.put<Array<INotification>>(`/notification/v1/notifications/toggleReadStatus`, ids);
    if (response.status !== HTTP_OK) {
      throw new Error();
    }
    return response.data;
  } catch (e: unknown) {
    return null;
  }
}

export async function putMarkAllAsRead() {
  try {
    const HttpClient = await getHttpClientWithAuth();
    const response = await HttpClient.put<Array<INotification>>(`/notification/v1/notifications/markAllAsRead`);
    return response.status === HTTP_OK;
  } catch (e: unknown) {
    return false;
  }
}
