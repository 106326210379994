import { Module } from 'vuex';
//http
import {
  getCurrentPricingConfiguration,
  getPricingConfigurationById,
  getPricingConfigurationVersions,
} from '@/http/PricingConfiguration';
//types
import { IPricingConfiguration, IPricingConfigurationVersion } from '@/types/IPricingConfiguration';

interface IPricingConfigurationState {
  pricingConfiguration: IPricingConfiguration | null;
  pricingConfigurationVersions: Array<IPricingConfigurationVersion>;
}

export enum PRICING_GETTERS {
  PRICING_CONFIGURATION = 'pricingConfiguration/pricingConfiguration',
  PRICING_CONFIGURATION_VERSIONS = 'pricingConfiguration/pricingConfigurationVersions',
}

export enum PRICING_ACTIONS {
  FETCH_PRICING_CONFIGURATION = 'pricingConfiguration/fetchPricingConfiguration',
  FETCH_PRICING_CONFIGURATION_VERSIONS = 'pricingConfiguration/fetchPricingConfigurationVersions',
}

const pricingConfigurationStore: Module<IPricingConfigurationState, unknown> = {
  namespaced: true,
  state: {
    pricingConfiguration: null,
    pricingConfigurationVersions: [],
  },

  mutations: {
    setPricingConfiguration(state, pricingConfiguration) {
      state.pricingConfiguration = pricingConfiguration;
    },
    setPricingConfigurationVersions(state, pricingConfigurationVersions) {
      state.pricingConfigurationVersions = pricingConfigurationVersions;
    },
  },
  actions: {
    async fetchPricingConfiguration({ commit }, pricingConfigurationId: number | null) {
      if (pricingConfigurationId) {
        const response = await getPricingConfigurationById(pricingConfigurationId);
        commit('setPricingConfiguration', response.data);
      } else {
        const response = await getCurrentPricingConfiguration();
        commit('setPricingConfiguration', response.data);
      }
    },
    async fetchPricingConfigurationVersions({ commit }) {
      const response = await getPricingConfigurationVersions();
      commit('setPricingConfigurationVersions', response.data);
    },
  },

  getters: {
    pricingConfiguration: (state) => state.pricingConfiguration,
    pricingConfigurationVersions: (state) => state.pricingConfigurationVersions,
  },
};
export default pricingConfigurationStore;
