import { TOASTBAR_TYPES } from '@/components/common/ToastBar.vue';
import { ref, Ref } from 'vue';
import { ALERT_TIMEOUT, ONE } from '@/constants';

interface Alert {
  text: string;
  type: TOASTBAR_TYPES;
}

const alerts: Ref<Alert[]> = ref([]);

export const useAlertService = () => {
  const closeAlert = (alert: Alert) => {
    const index = alerts.value.indexOf(alert);
    alerts.value.splice(index, ONE);
  };
  const setTimeoutAlert = (alert: Alert) => {
    setTimeout(() => {
      closeAlert(alert);
    }, ALERT_TIMEOUT);
  };

  const showAlert = (text: string, type: TOASTBAR_TYPES = TOASTBAR_TYPES.Positive) => {
    const alert = { text, type };
    alerts.value.unshift(alert);
    setTimeoutAlert(alert);
  };

  return {
    alerts,
    showAlert,
    closeAlert,
  };
};
