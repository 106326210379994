import { Module } from 'vuex';
import { ZERO, ONE } from '@/constants';
import { IProperty } from '@/types/IProperty';
import { IBorrower } from '@/types/IBorrower';
import { ILoan } from '@/types/ILoan';
import Company from '@/models/Company';
export interface CaseApplicationState {
  editableFields: Record<number, Array<string>>;
  company: Company | undefined;
  borrowerForms: Array<IBorrower> | [];
  propertyForms: Array<IProperty> | [];
  loanForm: ILoan | undefined;
}

export enum CaseApplicationGetters {
  IS_FIELD_EDITABLE = 'caseApplication/isFieldEditable',
  COMPANY = 'caseApplication/company',
  BORROWER_FORM = 'caseApplication/borrowerForm',
  PROPERTY_FORM = 'caseApplication/propertyForm',
  LOAN_FORM = 'caseApplication/loanForm',
}
export enum CaseApplicationMutations {
  SET_BORROWER_FORM = 'caseApplication/setBorrowerForm',
  SET_BORROWER = 'caseApplication/setBorrower',
  SET_PROPERTY_FORM = 'caseApplication/setPropertyForms',
  ADD_PROPERTY_FORM = 'caseApplication/addPropertyForm',
  REMOVE_PROPERTY_FORM = 'caseApplication/removePropertyForm',
  SET_PROPERTY = 'caseApplication/setProperty',
  SET_LOAN_FORM = 'caseApplication/setLoanForm',
  SET_COMPANY = 'caseApplication/setCompany',
}

/**
 * Case Application Store
 *
 * Used to store case application related information.
 */
const caseApplicationStore: Module<CaseApplicationState, unknown> = {
  namespaced: true,
  state: {
    editableFields: {},
    company: undefined,
    borrowerForms: [],
    propertyForms: [],
    loanForm: undefined,
  },
  mutations: {
    setEditableFields(state, fields) {
      state.editableFields = fields;
    },
    setBorrowerForm(state, borrowers: IBorrower[]) {
      state.borrowerForms = borrowers;
    },
    setBorrower(state, variable: { index: number; borrower: IBorrower }) {
      state.borrowerForms[variable.index] = variable.borrower;
    },
    setLoanForm(state, loan: ILoan) {
      state.loanForm = loan;
    },
    setPropertyForms(state, properties: IProperty[]) {
      state.propertyForms = properties;
    },
    addPropertyForm(state, property: IProperty) {
      (state.propertyForms as IProperty[]).push(property);
    },
    removePropertyForm(state, index: number) {
      state.propertyForms.splice(index, ONE);
    },
    setProperty(state, variable: { index: number; property: IProperty }) {
      state.propertyForms[variable.index] = variable.property;
    },
    setCompany(state, company: Company) {
      state.company = company;
    },
  },
  actions: {
    setEditableFields({ commit }, fields) {
      commit('setEditableFields', fields);
    },
  },
  getters: {
    isFieldEditable: (state) => (idProperty: number, key: string) => {
      const editableFields: string[] = state.editableFields[idProperty];
      if (editableFields) {
        if (editableFields.length === ZERO) {
          return true;
        }
        return editableFields.includes(key);
      }
      return true;
    },
    borrowerForm: (state) => state.borrowerForms,
    loanForm: (state) => state.loanForm,
    propertyForm: (state) => state.propertyForms,
    company: (state) => state.company,
  },
};

export default caseApplicationStore;
