import { Module } from 'vuex';
import { IBorrower } from '@/types/IBorrower';
import { postBorrower } from '@/http/Borrower';

import Borrower from '@/models/Borrower';

export interface IBorrowerStore {
  // replace with Borrower model
  borrowerData: IBorrower;
}

/**
 * Borrower Store
 *
 * Used to store borrower related information.
 */
const borrowerStore: Module<IBorrowerStore, unknown> = {
  namespaced: true,
  state: {
    borrowerData: new Borrower({}),
  },
  mutations: {
    setBorrowerData(state, payload: IBorrower) {
      state.borrowerData = payload;
    },
  },
  actions: {
    setBorrowerData({ commit }, payload: IBorrower) {
      // TODO replace it when we refactor the Borrower API
      return Object.values(payload).map(async (req) => {
        await postBorrower(req, '');
        commit('setBorrowerData', req);
      });
    },
  },
  getters: {
    getBorrowerData: (state) => state.borrowerData,
  },
};

export default borrowerStore;
