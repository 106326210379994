import httpClient from '@/http';
import { AxiosPromise } from 'axios';
import { authPlugin } from '@/services/auth';

/**
 * @returns {Promise<AxiosPromise>}
 * @param loanCaseId
 */
export async function manuallyValidateOpenBanking(loanCaseId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return httpClient({
    method: 'PUT',
    url: `/application/validation/v1/validation/initiateOpenBanking`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      loanCaseId,
    },
  });
}
/**
 * @param {number} validationId
 * @returns {Promise<AxiosPromise>}
 */
export async function manuallyValidateOnfido(validationId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return httpClient({
    method: 'PUT',
    url: `/application/validation/v1/validation/${validationId}/initiateOnfido`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
/**
 *
 * @param {string} loanCaseId
 * @returns {Promise<AxiosPromise>}
 */
export async function checkOpenBanking(loanCaseId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return httpClient({
    method: 'POST',
    url: `/application/validation/v1/validation/checkOpenBanking`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      loanCaseId,
    },
  });
}

/**
 *
 * @param {string} loanCaseId
 * @param checkId
 * @returns {Promise<AxiosPromise>}
 */
export async function checkOnfido(loanCaseId: number, checkId: number): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return httpClient({
    method: 'POST',
    url: `/application/validation/v1/validation/${checkId}/checkOnfido`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function downloadOpenBankingReport(loanCaseId: string): Promise<AxiosPromise> {
  const token = await authPlugin.getTokenSilently();

  return httpClient({
    method: 'GET',
    url: `/application/validation/v1/validation/${loanCaseId}/downloadOpenBankingReport`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}
