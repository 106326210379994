export enum CASE_STATUS {
  // Please don't alter the order of this enum as it is relevant for the Case Summary
  PRE_DRAFT = 'PRE_DRAFT',
  DRAFT = 'DRAFT',
  ACTIVE_SUMMARY_REQUEST = 'ACTIVE_SUMMARY_REQUEST',
  ACTIVE_INITIAL_QUOTES = 'ACTIVE_INITIAL_QUOTES',
  CLOSED_EXPIRED = 'CLOSED_EXPIRED',
  ACTIVE_CONDITIONAL_OFFER = 'ACTIVE_CONDITIONAL_OFFER',
  ACTIVE_VALIDATIONS = 'ACTIVE_VALIDATIONS',
  CLOSED_REJECTED = 'CLOSED_REJECTED',
  ACTIVE_FINAL_CALL = 'ACTIVE_FINAL_CALL',
  ACTIVE_SETUP_LEGALS = 'ACTIVE_SETUP_LEGALS',
  ACTIVE_LEGALS = 'ACTIVE_LEGALS',
  CLOSED_APPROVED = 'CLOSED_APPROVED',
  ACTIVE_DISBURSED = 'ACTIVE_DISBURSED',
}
