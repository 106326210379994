import routeNames from '@/router/routeNames';
import { CASE_STATUS } from '@/types/CaseStatus';
import { STATUS_TO_TEXT } from '@/constants/statusToText';

export interface IStatusToRoute {
  [key: string]: {
    route: string;
    text: string;
    color: string;
  };
}

export const STATUS_TO_ROUTE: IStatusToRoute = {
  [CASE_STATUS.PRE_DRAFT]: {
    route: routeNames.PRE_APPLICATION,
    text: STATUS_TO_TEXT.PRE_DRAFT,
    color: 'tc-positive',
  },
  [CASE_STATUS.DRAFT]: {
    route: routeNames.APPLICATION,
    text: STATUS_TO_TEXT.DRAFT,
    color: 'tc-positive',
  },
  [CASE_STATUS.ACTIVE_SUMMARY_REQUEST]: {
    route: routeNames.CASE_SUMMARY,
    text: STATUS_TO_TEXT.ACTIVE_SUMMARY_REQUEST,
    color: 'tc-positive',
  },
  [CASE_STATUS.ACTIVE_INITIAL_QUOTES]: {
    route: routeNames.INDICATIVE_QUOTES,
    text: STATUS_TO_TEXT.ACTIVE_INITIAL_QUOTES,
    color: 'tc-positive',
  },
  [CASE_STATUS.ACTIVE_CONDITIONAL_OFFER]: {
    route: routeNames.CONDITIONAL_OFFER,
    text: STATUS_TO_TEXT.ACTIVE_CONDITIONAL_OFFER,
    color: 'tc-positive',
  },
  [CASE_STATUS.ACTIVE_VALIDATIONS]: {
    route: routeNames.VALIDATIONS,
    text: STATUS_TO_TEXT.ACTIVE_VALIDATIONS,
    color: 'tc-positive',
  },
  [CASE_STATUS.ACTIVE_FINAL_CALL]: {
    route: routeNames.SPEAK_WITH,
    text: STATUS_TO_TEXT.ACTIVE_FINAL_CALL,
    color: 'tc-positive',
  },
  [CASE_STATUS.ACTIVE_SETUP_LEGALS]: {
    route: routeNames.LEGALS_UPLOAD,
    text: STATUS_TO_TEXT.ACTIVE_SETUP_LEGALS,
    color: 'tc-positive',
  },
  [CASE_STATUS.ACTIVE_LEGALS]: {
    route: routeNames.LEGALS,
    text: STATUS_TO_TEXT.ACTIVE_LEGALS,
    color: 'tc-positive',
  },
  [CASE_STATUS.ACTIVE_DISBURSED]: {
    route: routeNames.DISBURSAL,
    text: STATUS_TO_TEXT.ACTIVE_DISBURSED,
    color: 'tc-positive',
  },
  [CASE_STATUS.CLOSED_EXPIRED]: {
    route: routeNames.CASE_SUMMARY,
    text: STATUS_TO_TEXT.CLOSED_EXPIRED,
    color: 'tc-black',
  },
  [CASE_STATUS.CLOSED_REJECTED]: {
    route: routeNames.CASE_SUMMARY,
    text: STATUS_TO_TEXT.CLOSED_REJECTED,
    color: 'tc-negative',
  },
  [CASE_STATUS.CLOSED_APPROVED]: {
    route: routeNames.DISBURSAL,
    text: STATUS_TO_TEXT.CLOSED_APPROVED,
    color: 'tc-positive',
  },
};
