import { Module } from 'vuex';
import {
  getNotifications,
  getUnreadCount,
  NO_NOTIFICATIONS_COUNT,
  putMarkAllAsRead,
  putToggleReadStatus,
} from '@/services/NotificationsService';
import { FIRST_INDEX } from '@/constants';
import _ from 'lodash';
import { TNotificationSections } from '@/types/INotificationCase';

interface INotificationsStore {
  lastWasEmpty: boolean;
  lastHadError: boolean;
  notifications: TNotificationSections;
  unreadNotifications: number;
}

const notificationsStore: Module<INotificationsStore, unknown> = {
  namespaced: true,

  state: {
    unreadNotifications: NO_NOTIFICATIONS_COUNT,
    lastWasEmpty: false,
    lastHadError: false,
    notifications: [],
  },

  getters: {
    notificationsPaths: (state) => {
      const ids: Map<number, [number, number, number]> = new Map();

      state.notifications.forEach(({ groups }, ageIndex) => {
        groups.forEach(({ notifications }, groupIndex) => {
          notifications.forEach((notification, notificationIndex) => {
            ids.set(notification.id, [ageIndex, groupIndex, notificationIndex]);
          });
        });
      });

      return ids;
    },

    countNotifications: (state, getters) => getters.notificationsPaths.size,
  },

  mutations: {
    setUnreadCount(state, payload: number) {
      state.unreadNotifications = payload;
    },
    setLastHadError(state, payload: boolean) {
      state.lastHadError = payload;
    },
    setNotifications(state, payload: TNotificationSections) {
      state.lastWasEmpty = !payload.length;
      state.notifications = payload;
    },
    appendNotifications(state, newNotifications: TNotificationSections) {
      state.lastWasEmpty = !newNotifications.length;
      const list = _.cloneDeep(state.notifications);

      const listBySection = _.keyBy(list, ({ age }) => age);

      newNotifications.forEach(({ age, groups }) => {
        if (listBySection[age] === undefined) {
          list.push({ age, groups });
          return;
        }

        const section = listBySection[age].groups;
        const sectionByGroup = _.keyBy(section, ({ groupKey }) => groupKey);

        groups.forEach(({ groupKey, notifications }) => {
          if (sectionByGroup[groupKey] === undefined) {
            section.push({ groupKey, notifications });
            return;
          }
          const group = sectionByGroup[groupKey].notifications;
          const groupById = _.keyBy(group, ({ id }) => id);

          notifications.forEach((notification) => {
            if (groupById[notification.id] === undefined) {
              group.push(notification);
              return;
            }

            const oldNotification = groupById[notification.id];

            Object.entries(notification).forEach(([field, value]) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Not sure what this is complaining about
              oldNotification[field] = value;
            });
          });
        });
      });

      state.notifications = list;
    },
  },

  actions: {
    async fetchNotifications({ commit }, { page = FIRST_INDEX, showOnlyCritical = false }) {
      const notifications = await getNotifications({
        page,
        showOnlyCritical,
      }).catch(() => {
        commit('setLastHadError', true);
      });

      if (!notifications) {
        return;
      }

      commit('setLastHadError', false);

      if (page === FIRST_INDEX) {
        commit('setNotifications', notifications);
      } else {
        commit('appendNotifications', notifications);
      }
    },

    async fetchUnreadCount({ commit }) {
      const { count } = await getUnreadCount();

      commit('setUnreadCount', count);
    },

    async toggleReadStatus({ commit, state, getters }, { ids }: { ids: Array<number> }) {
      const newNotifications = await putToggleReadStatus(ids);
      if (!newNotifications) {
        return;
      }

      const list = _.cloneDeep(state.notifications);

      newNotifications.forEach((notification) => {
        if (!getters.notificationsPaths.has(notification.id)) {
          return;
        }

        const path = getters.notificationsPaths.get(notification.id);

        const [age, caseNumber, index] = path;
        list[age].groups[caseNumber].notifications[index] = notification;
      });

      commit('setNotifications', list);
    },

    async markAllAsRead({ commit, state, getters }) {
      const success = await putMarkAllAsRead();
      if (!success) {
        return;
      }
      const list = _.cloneDeep(state.notifications);

      for (const [age, caseNumber, index] of getters.notificationsPaths.values()) {
        list[age].groups[caseNumber].notifications[index].markedAsRead = true;
      }

      commit('setNotifications', list);
    },
  },
};

export default notificationsStore;
